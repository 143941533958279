<template>
    <div class="login">
      Loading...
    </div>
</template>

<script>
import axios from "@/libs/axios";
  import useJwt from '@/auth/jwt/useJwt'
  import {initialAbility} from "@/libs/acl/config";
  import {getHomeRouteForLoggedInUser} from "@/auth/utils";

  export default {
    beforeCreate() {
      const userToken = this.$route.query.userToken
      const userGroup = this.$route.query.user
      const cameFromURL = this.$route.query.url

      if (!userToken) this.$router.replace({ name: 'auth-login' })

      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem('userData')

      this.$store.commit('SET_USER_DATA', null)
      this.$store.commit('CHANGE_USER_STATUS', false)
      this.$store.commit('family/SET_FAMILY_ID', null)

      this.$ability.update(initialAbility)

      const authAsUser = () => axios
          .get('/user-auth/me', {headers: {'Authorization': `Bearer ${userToken}`}})
          .then(response => {
            sessionStorage.setItem('loggedAsUser', true)
            sessionStorage.setItem('cameFromURL', cameFromURL)
            const userData = response.data.data
            useJwt.setToken(userToken)
            userData.role = userGroup
            userData.ability = [{"action":"manage","subject":"all"}]
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)
            this.$store.commit('CHANGE_USER_STATUS', userData.id)
            this.$store.commit('SET_USER_DATA', userData)
            this.$router.replace(getHomeRouteForLoggedInUser(userGroup))
          })

      authAsUser()

    },
  }
</script>

<style>
</style>
